<template>
    <article class="columns">
      <section class="column">
        <!-- <div class="modal" v-bind:class="{ 'is-active': isShowDialog }"> -->
       <b-modal
            v-model="isShowDialog"
            has-modal-card
            full-screen
            :can-cancel="false"
            @close="cancelForm"
            >
            <div class="modal-background"></div>
            <div class="modal-card scrollable" style="width: auto">
                <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
                <header class="modal-card-head">
                <p class="modal-card-title has-text-centered">PRODUCT DETAILS</p>
                <button class="delete is-danger" type="button" @click="cancelForm">Cancel</button>
                </header>
                <section class="modal-card-body">
                    <div class="columns">
                        <div class="column box veecli-box">
                            <div class="columns">
                                <div class="column">
                                    Name:
                                </div>
                                <div class="column has-text-weight-bold">
                                    {{ product.name }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    ID:
                                </div>
                                <div class="column has-text-weight-bold">
                                    {{ product.id }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    UPC:
                                </div>
                                <div class="column has-text-weight-bold">
                                    {{ product.upc }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    Department:
                                </div>
                                <div class="column has-text-weight-bold">
                                    {{ product.departments.name }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    Mapping:
                                </div>
                                <div class="column has-text-weight-bold">
                                    {{ product.departments.storeMapping }}
                                    
                                </div>
                            </div>

                            
                        </div>
                        <div class="column box veecli-box">
                            <div class="columns">
                                <div class="column">
                                    Price:
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <VeeInput
                                            type="currency" 
                                            v-model="product.price"
                                            name="productPrice"
                                            :value="product.price"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="allowEdit"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

        
                </section>
        
                <footer class="modal-card-foot">
                <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
                <button class="button" type="button" @click="cancelForm">Cancel</button>
                <span class='is-danger'></span>
                </footer>
            <!-- </form> -->
        
            </div>
        <!-- </div> -->
        </b-modal>

      </section>
    </article>
</template>


<script>

import VeeInput from "../../../../components/app/VeeInput.vue"
import UserStore from "../../../../store/UserStore"
import axios from "axios"

export default {

    props: {
        isShowDialog: Boolean,
        product: Object,
        allowEdit: Boolean
    },

    data() {
        return {
            isDisabled: false,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
        }
    },

    components: {
        VeeInput
    },

    methods: {

        submitForm() {
            var url = process.env.VUE_APP_API_ROOT_URL + "/products"
            axios.put(url,[this.product],
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken,
                    'correlation-id': this.$uuid.v1()
                },                   
            })
            console.log("Updated", this.product)
            this.closeDialog()
        },

        cancelForm() {
            this.closeDialog()
        },

        closeDialog() {
            this.$emit('hide-dialog')
        },

        getImage() {
            var url = "https://api.upcdatabase.org/product/" + this.product.upc
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer 0862074714DF1336E276280F8DBE94A4'
                    },                   
                })
            .then( (response) => {
                console.log(response)
                
            })
        },

    },



}
</script>

<style>
.veecli-box {
    margin: 5px;
    display: flex;
    flex-flow: column;
    height: 100%;
}
</style>