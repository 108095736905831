<template>
    <section v-if="getActiveStore.role != 'STAFF'">
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">PRODUCTS</div>
                <!-- <div class="level-right has-text-centered">
                    <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button>
                </div> -->
            </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="columns" v-if="isEditable">
            <div class="column">

            </div>
            <div class="column">
                <b-taglist attached>
                    <b-tag type="is-gray">

                        <b-switch
                            :true-value="true"
                            :false-value="false"
                            size="is-small"
                            v-model="showOnlyUpdated"
                            @input="filtersChanged"
                            type="is-success">
                            Display Only Products Updated with New Price
                        </b-switch>
                        
                        <!-- <b-checkbox 
                            @input="filtersChanged"
                            v-model="showOnlyUpdated" 
                            :value="trueValue"
                            >
                            Display Only Products Updated with New Price
                        </b-checkbox> -->
                    </b-tag>
                    <b-tag type="is-success"><span class="is-size-6" >{{ productsUpdated.length }}</span></b-tag>
                </b-taglist>
            </div>
            <VeeSavePane
                :row1="'Price Updated for ' + productsUpdated.length + ' Products.'"
                :cancelEnable="productsUpdated.length > 0"
                :saveEnable="productsUpdated.length > 0"
                :isEditing="productsUpdated.length > 0"
                @cancel-edit="cancelAllUpdates()"
                @save-edit="saveAllUpdates()"
                >

            </VeeSavePane>
        </div>

        <div class="panel-block">
            <b-table class="control" 
                :data='records'
                :paginated="isPaginated"
                :per-page="perPage"
                :current.sync="page"
                backend-pagination
                backend-sorting
                :backend-filtering="backendFiltering"
                :debounce-search="searchStartMilliSecs"
                :total="totalRecords"
                @page-change="onPageChange"
                @sort="onColumnSort"
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                :row-class="getRowClass"
                :default-sort="sortField"
                :defaultSortDirection='sortOrder'
                @filters-change="filtersChanged"
                >

            <template>
                <b-table-column field="upc" label="UPC" searchable sortable sorticon v-slot="props">
                    {{ props.row.upc }}-{{ zeroPad(props.row.upcModifier,3) }}
                </b-table-column>
                
                <b-table-column class="is-2" field="barCode"  v-slot="props" label="Barcode" v-if="isShowBarcode">
                    <vue-barcode 
                        v-bind:value="props.row.upc" 
                        height="20" 
                        width="1" 
                        displayValue="false">
                        N/A
                    </vue-barcode>
                </b-table-column>                
                <b-table-column field="name" label="Name" searchable sortable sorticon  v-slot="props">
                   {{ props.row.name }}
                </b-table-column>
                <b-table-column field="departments.name" label="Dept" searchable sortable sorticon  v-slot="props">
                   {{ ( props.row.departments ? props.row.departments.name : 'n/a' ) }}
                </b-table-column>
                <b-table-column field="departments.storeMapping" label="Mapping" searchable sortable sorticon  v-slot="props">
                   {{ ( props.row.departments ? props.row.departments.storeMapping : 'n/a' ) }}
                </b-table-column>
                <b-table-column field="departments.percentage" label="Margin" sortable sorticon  v-slot="props">
                   {{ ( props.row.departments ? props.row.departments.percentage +"%" : 'n/a' ) }}
                </b-table-column>
                <b-table-column numeric  field="averageCost" label="Cost" v-slot="props">
                    {{ props.row.averageCost | formatCurrency }} 
                </b-table-column>
                <b-table-column numeric  field="suggestedSellPrice" label="Suggested Price" v-slot="props" >
                    <span :class="suggestedPriceClass(props.row)">
                        {{ props.row.suggestedSellPrice | formatCurrency }} 
                    </span>
                </b-table-column> 
                <b-table-column numeric field="priceCurrent" label="Price Current" sortable sorticon  v-slot="props">
                    {{ props.row.priceCurrent | formatCurrency }}
                </b-table-column>
                <b-table-column numeric field="price" label="New Price" v-slot="props" sortable sorticon v-if="isEditable">
                    <VeeInput 
                        type="currency"
                        v-model="props.row.price"
                        name="price"
                        :value="props.row.price"
                        :countryCurrencyCode="countryCurrencyCode"
                        :editable="isEditable"
                        @input="priceUpdated(props.row)"
                        />
                </b-table-column>
                <b-table-column numeric field="reset" label="" sortable sorticon  v-slot="props">
                    <b-button outlined type="is-danger is-small"
                        v-if="props.row.price != props.row.priceCurrent"
                        @click="resetUpdate(props.row)"
                        tabindex="-1"
                        icon-right="trash-alt" />
                        <!-- <b-icon
                            v-if="props.row.price != props.row.priceCurrent"
                            pack="fas"
                            icon="trash-alt"
                            @click="resetUpdate(props.row)"
                            size="is-small has-text-danger"></b-icon> -->

                </b-table-column>
                <b-table-column field="active" label="Active" v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column>
            </template>
            
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <template slot="top-left">
                <div  class="is-size-6">
                    Products per page: 
                    <select class="is-size-6" name="perPage" v-model="perPage" @change="onPageChange(1)">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>                    
                </div>
            </template>

            <template slot="header">
                
            </template>
            <template slot="footer">
                
            </template>    

        </b-table>  

        </div>

    </div>

    <product-details 
        :isShowDialog="isShowDialog"
        :product="product"
        :allowEdit="allowEdit"
        @hide-dialog="toggleDialog">
    </product-details>

    </section>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'
import VeeInput from "../../../../components/app/VeeInput"
import VeeSavePane from "../../../../components/app/VeeSavePane"
import VueBarcode from 'vue-barcode'

import axios from 'axios'

import ProductDetails from './ProductDetails'

export default {

    components: {
        DataLoading,
        VeeInput,
        VeeSavePane,
        ProductDetails,
        VueBarcode
    },

    metaInfo: {
        title: 'Products'
    },

    data() {
        return {
            user: [],
            records: [],
            isPaginated: true,
            perPage: 50,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            sortField: 'name',
            sortOrder: 'asc',
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            isShowPDFDialog: false,
            backendFiltering: true,
            searchName: "",
            searchUPC: "",
            searchDesc: "",
            searchDept: "",
            searchMap: "",
            searchStartMilliSecs: 1000,
            product: {"departments": {}, priceCurrent: 0},
            productsUpdated: [],
            allowEdit: true,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            isEditable: false,
            showOnlyUpdated: false,
            trueValue: true,
            falseValue: false,
            isShowBarcode: false,
        }
    },

    methods: {

        fetchRecords() {

            if (this.user.activeStore) {
                var showPage = this.page - 1
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/products?withInventory=false&size=" + this.perPage + "&page=" + showPage +
                        "&sort=" + this.sortField + "," + this.sortOrder 
                        if (this.searchName) {
                            url = url + "&name=" + this.searchName
                        }
                        if (this.searchUPC) {
                            url = url + "&upc=" + this.searchUPC
                        }
                        if (this.searchDesc) {
                            url = url + "&description=" + this.searchDesc
                        }
                        if (this.searchDept) {
                            url = url + "&departments.name=" + this.searchDept
                        }
                        if (this.searchMap) {
                            url = url + "&departments.storeMapping=" + this.searchMap
                        }
                        
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data.content
                        this.records.forEach(product => {
                            var index = this.productsUpdated.findIndex((map) => map.upc === product.upc)
                            if (index > -1) {
                                product.priceCurrent = this.productsUpdated[index].priceCurrent
                                product.price = this.productsUpdated[index].price
                            } else {
                                product.priceCurrent = product.price
                            }
                            
                        })
                        this.totalPages = response.data.data.totalPages
                        this.page = response.data.data.number
                        this.perPage = response.data.data.size
                        this.totalRecords = response.data.data.totalElements
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            this.isLoading = false
            
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },
   
        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        statusClass() {
            return "has-background-primary"
        },

        onColumnSort(field,order) {
            this.sortField = field
            this.sortOrder = order
            if (!this.showOnlyUpdated) {
                this.fetchRecords()
            }
        },

        onPageChange(page) {
            this.page = page
            if (!this.showOnlyUpdated) {
                this.fetchRecords()
            }
        },        

        filtersChanged(event) {
            this.isLoading = true
            this.records = []
            if (this.showOnlyUpdated) {
                this.isPaginated = false
                this.records = this.productsUpdated
                this.totalRecords = this.productsUpdated.length
                this.totalPages = this.totalRecords / this.perPage
            } else {
                this.isPaginated = true
                this.searchName = event.name
                this.searchUPC = event.upc
                this.searchDesc = event.description
                this.searchDept = event["departments.name"]
                this.searchMap = event["departments.storeMapping"]
                this.fetchRecords()
            }
            this.isLoading = false
        },

        doProductAction(action, product) {
            this.product = product
            this.toggleDialog()
        },

        getRowClass(row) {
            if (row.price != row.priceCurrent) {
                return 'has-background-warning-light'
            }
        },

        priceUpdated(row) {
            
            var index = this.productsUpdated.findIndex((map) => map.upc === row.upc)
            if (index > -1) {
                if (row.price != row.priceCurrent) {
                    this.productsUpdated[index] = row
                    // console.log("Already updated Price",this.productsUpdated[index].upc, this.productsUpdated[index].priceCurrent, this.productsUpdated[index].price)
                } else {
                    this.productsUpdated.splice(index, 1)
                    // console.log("Removed Updated Price")
                }
            } else {
                if (row.price != row.priceCurrent) {
                    this.productsUpdated.push(row)
                    index = this.productsUpdated.length - 1
                    // console.log("New Price",this.productsUpdated[index].upc, this.productsUpdated[index].priceCurrent, this.productsUpdated[index].price)
                }
            }

        },

        resetUpdate(row) {
            var index = this.records.findIndex((map) => map.upc === row.upc)
            if (index > -1) {
                if (row.price != row.priceCurrent) {
                    this.records[index].price = this.records[index].priceCurrent
                    var indexUpd = this.productsUpdated.findIndex((map) => map.upc === row.upc)
                    if (indexUpd > -1) {
                        this.productsUpdated.splice(indexUpd, 1)
                    }
                }
            }
        },

        cancelAllUpdates() {
            this.productsUpdated = []
            this.records = []
            this.showOnlyUpdated = false
            this.isPaginated = true
            this.fetchRecords()
        },

        saveAllUpdates() {
            var url = process.env.VUE_APP_API_ROOT_URL + "/products"
            axios.put(url,this.productsUpdated,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken,
                    'correlation-id': this.$uuid.v1()
                },                   
            })
            .then(response => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Updated Prices Queued.  POS will be updated within 15 mins.'+response.status,
                    position: 'is-top',
                    type: 'is-success'
                })
                this.productsUpdated = []
                this.records = []
                this.showOnlyUpdated = false
                this.isPaginated = true
                this.fetchRecords()
            })
            .catch(err => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Failed to Save. Cleared all updates. '+err,
                    position: 'is-top',
                    type: 'is-danger'
                })
                // this.productsUpdated = []
                // this.records = []
                // this.showOnlyUpdated = false
                // this.isPaginated = true
                // this.fetchRecords()
            })

        },

        suggestedPriceClass(row) {
            if (!row.suggestedSellPrice) {
                return "has-text-warning-dark"
            } 
            if (row.suggestedSellPrice >= row.priceCurrent) {
                return "has-text-danger-dark"
            } 
            return "has-text-success"
        },

    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    }, 

    mounted() {

        this.user = this.getUser()
     
        this.fetchRecords()

    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}

.disabledsearch {
    pointer-events:auto;
    color:#AAA;
    background:#F5F5F5;
}
</style>